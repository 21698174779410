.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }

    .title {
        @extend .font-1-medium;
        text-transform: uppercase;
        color: $color-secondary;
        font-size: $font-size-36;
        text-align: center;
        text-shadow: 4px 4px 5px $color-primary,
                    -4px -4px 5px $color-primary,
                    4px -4px 5px $color-primary,
                    -4px 4px 5px $color-primary;
    }
    hr {
      max-width: 500px;
      margin: 25px auto;
      border-top: 3px solid $color-secondary;
      box-shadow: 4px 4px 5px $color-primary,
                  -4px -4px 5px $color-primary,
                  4px -4px 5px $color-primary,
                  -4px 4px 5px $color-primary;
      @media screen and (max-width: $size-xs-max){
        max-width: 250px;
      }
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        width: 100%;
        top: $size-nav-height + 50px;
        z-index: $z-index-slide-caption;
        display: flex;
        align-items: center;
        justify-content: center;
    }


.ls-bottom-nav-wrapper {
  position: absolute;
  top: 50%;
  right: 40px;
  height: auto;
  display: block;
}

.ls-numbers .ls-bottom-slidebuttons {
  top: 50%!important;
  transform: translateY(-50%);
  height: auto;
  @media screen and (max-width: $size-xs-max){
    display: none;
  }
  a {
    display: block;
    margin: 25px 0;
    height: 25px;
    width: 25px;
    background: $color-primary;
    &::before{
      content: '';
    }
    &.ls-nav-active {
      background: $color-white;
      // height: 21px;
      // width: 21px;
    }
    &:hover {
      background: $color-primary;
    }
  }
}

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}
