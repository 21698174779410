// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.separator-custom-bottom {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 15px;
        position: absolute;
        background: url(../images/bande_top.png) no-repeat center center;
        background-size: cover;
    }
}

.custom-separator {
    position: relative;
    &::after {
        content: '';
        top: 100%;
        z-index: 0;
        height: 15px;
        width: 100%;
        transition: .5s;
        position: absolute;
        border: 0 solid $color-2;
        background: url(../images/bande_bottom.png) no-repeat center center;
        background-size: cover;
        border-right: 0;
        border-left: 0;
    }
}
.nom {
  color: $color-1;
  font-weight: 700;
}
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-header';
//@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/

div.container-fluid.row {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  & > div {
    width: 50%;
    @media screen and (max-width: $size-xs-max) {
      width: 100%;
    }
  }

}
/*****************************************************/
/* INDEX.PHP
/*****************************************************/
section.section-logos {
  padding: 50px 10px;

  div.left-side,
  div.right-side {
    height: 100%;
    margin: 20px 0;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  div.content-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  div.logo-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div.item {
      margin: 15px;
      max-width: 30%;
      font-size: $font-size-30;
      line-height: 2;
      font-weight: 500;
      font-style: italic;
    }
  }

  h3 {
    font-size: $font-size-24;
    margin-bottom: 20px;

  }


  @media screen and (max-width: $size-xs-max){

    div.content-box {
      align-items: center;
    }
    div.logo-box {
      justify-content: center;
      flex-wrap: wrap;
    }
    h3 {
      text-align: center;
    }
  }
}


section.section-02 {
  margin-top: 15px;

  div.left-side,

  div.right-side {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div.text-box {
      max-width: 80%;
    }
  }

  div.right-side {
    background-color: $color-1;
  }
  div.left-side {
    background-color: $color-2;
  }

  p {
    color: $color-white;
    font-size: $font-size-22;
    line-height: 1.5;
    font-weight: 400;
    //text-align: justify;
  }

  ul {
    list-style: none;
    li {
      color: $color-2;
      font-weight: 700;
      font-size: $font-size-26;
      line-height: 2;
      position: relative;
      text-transform: inherit;
      &::before {
        content: ' ';
        background-image: url('../images/point.png');
        background-position: center;
        background-size: 100% 100%;
        height: 22px;
        width: 22px;
        position: absolute;
        left: -40px;
        top: 15px;
        @media screen and (max-width: $size-xs-max){
          top: 7px;
          left: -30px;
        }
      }
    }
  }
}

section.section-03 {

  div.left-side {
    background-image: url('../images/accueil_photo_01.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $size-xs-max){
      height: 400px;
      background-position: right;
    }
  }

  div.right-side {
    padding: 15px 0;
    & div.burst {
      margin: 10px;
      display: flex;
      padding: 3vw 0;
      align-items: center;
      border: 2px solid $color-2;
      justify-content: flex-start;
      box-shadow: 5px 5px 5px $color-black;

      div.arrow {
        position: relative;
        width: 20%;
        max-width: 140px;
         img.overlay {
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           opacity: 0;
           transition: .3s;
           visibility: hidden;
         }
      }

      div.txt {
        margin-left: 15px;
        width: 75%;

        h3 {
          font-size: 1.9vw;
          text-transform: inherit;
          span {text-transform: uppercase;}
        }
        h4 {
          color: $color-2;
          font-weight: 400;
          font-size: 1.9vw;
        }

      }

      &:hover {
        div.arrow {
          position: relative;
           img.overlay {
             opacity: 1;
             transition: .6s;
             visibility: visible;
           }
         }
      }

      &.burst-top {
        background: linear-gradient(to right , $color-1, $color-white);
        transition: .3s;
        &:hover {
          background: linear-gradient(to right , $color-grey, $color-white);
          transition: .6s;
        }
      }
      &.burst-bottom {
        background: linear-gradient(to right , $color-grey, $color-white);
        transition: .3s;
        &:hover {
          background: linear-gradient(to right , $color-1, $color-white);
          transition: .6s;
        }
      }

      @media screen and (max-width: $size-xs-max){
        padding: 30px 0;
        div.txt {
          margin-left: 15px;
          width: 75%;
          h3, h4 {font-size: 4vw;}
        }
      }

    }

  }
}

/*******************************************************************************
* X PAGE
*/

/*******************************************************************************
* .php 
*******************************************************************************/
/* */
/*******************************************************************************
* end .php 
*******************************************************************************/

/*******************************************************************************
* location-lift-chariots-elevateurs-laval-montreal.php
*******************************************************************************/
/*contact form*/

/*//////////////////////////////////////////////////////////////////
*/

.colorcontact{
  background-color: #052c65;
}

.container-contact100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  /*flex-wrap: wrap;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.wrap-contact100 {
  max-width: 960px;
  background: #fff;
  border-radius: 2px;
  padding: 66px 115px 59px 115px;
  position: relative;
}


/*==================================================================
[ Form ]*/

.contact100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact100-form-title {
  display: block;
  width: 100%;
  font-family: Raleway-Black;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 32px;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  background-color: transparent;
  margin-bottom: 35px;
}

.rs1-wrap-input100 {
  width: calc((100% - 30px) / 2);
}

.label-input100 {
  /*font-family: Raleway-SemiBold;*/
  font-size: 14px;
  color: #eecd33;
  line-height: 1.5;
}

.input100 {
  display: block;
  width: 100%;
  /*background: #f2f2f2;*/
  /*border-radius: 2px;*/

  /*font-family: Raleway-SemiBold;*/
  font-size: 20px;
  color: #686868;
  line-height: 1.2;
  padding: 0 28px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  margin-top: 10px;
}


/*---------------------------------------------*/
input.input100 {
  height: 62px;
}


textarea.input100 {
  min-height: 162px;
  padding-top: 19px;
  padding-bottom: 13px;
}

.input100:focus {
  background: #e6e6e6;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5px;
}

.wrap-contact100-form-btn {
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  overflow: hidden;
}

/*.contact100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #df2351;
  background: -webkit-linear-gradient(right, #2f91ff, #00c513, #2f91ff, #00c513);
  background: -o-linear-gradient(right, #2f91ff, #00c513, #2f91ff, #00c513);
  background: -moz-linear-gradient(right, #2f91ff, #00c513, #2f91ff, #00c513);
  background: linear-gradient(right, #2f91ff, #00c513, #2f91ff, #00c513);
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}*/

.contact100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 62px;

  font-size: 16px;
  color: #052c65;  /*blue*/
  background-color: #eecd33;  /*yellow*/
  line-height: 1.2;
  text-transform: uppercase;
}

.wrap-contact100-form-btn:hover .contact100-form-bgbtn {
  left: 0;
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .wrap-contact100 {
    padding: 66px 80px 59px 80px;
  }
}

@media (max-width: 768px) {
  .rs1-wrap-input100 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-contact100 {
    padding: 66px 15px 59px 15px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  bottom: calc((100% - 34px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 10px;
  pointer-events: none;

  font-family: Raleway-SemiBold;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  bottom: calc((100% - 34px) / 2);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


/*==================================================================
[ Contact more ]*/

.contact100-more {
  display: block;
  max-width: 960px;
  font-family: Raleway-SemiBold;
  font-size: 20px;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  padding: 40px 0 10px 0;
}

.form-check label{
 color:#FFF;
 font-weight:300; 
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    filter: alpha(opacity=65);
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*******************************************************************************
* End location-lift-chariots-elevateurs-laval-montreal.php 
*******************************************************************************/

/*******************************************************************************
* forklift-chariot-longueuil-rive-sud.php
*******************************************************************************/
.color-row-fcl{
  background-color: #052c65;  /*blue*/
}

.text-box-coord{
  margin:20px;  
}

.address{
  color: #eecd33;
  font-weight:100;
}

.phone{
  color: #FFF;
  font-weight:100;
}

.text-box-horaire{
  margin:20px;  
}

.table-horaire{
  padding:20px;
}

.table-horaire th{
  color:#eecd33;
  font-weight:600;
}

.table-horaire td{
  color: #FFF;
  font-weight:100;
  padding:3px;
}

/*.horaire{
  color: #FFF;
  font-weight:100;
}*/
/*******************************************************************************
* end forklift-chariot-longueuil-rive-sud.php.php 
*******************************************************************************/

/*******************************************************************************
* reparation-chariot-elevateur-rive-nord.php 
*******************************************************************************/
.color-row-rce{
  background-color: #eecd33;  /*yellow*/
}

.uno{
  text-align:center;
}

.uno img { 
 width:50%;
}

.centrado-rcern{
    position: absolute;
    top: 50%;
    left: 50%;   
    color: #FFF;
    transform: translate(-50%, -50%);
}

.text-over-moyen-right-rce{
  padding:10%;  
}

.text-over-moyen-right-rce p{
  color: $color-2;
  font-weight:100;
}

/* row foto señor operando maquina */

.color-row-rce2{
 background-color: #052c65;  /*blue*/ 
}

.text-over-moyen-left{
 position:relative;
 /*width:90%;*/
}

.text-over-moyen-left p{
 position:absolute;
 top:40px;
 left:40px;
 color:#FFF;
 font-weight:100; 
}

.uno2{
  text-align:center;
}

.uno2 img { 
 width:100%;
}

/* row ultima */

.text-usage-rce ul li {
  text-transform: none;
  color:#052c65;
  font-weight: 300;
  line-height: 3.5ex;
}

.imgcollef-ult-y{
 /* background: url('../images/entretien_burst_02.jpg');*/
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

/*******************************************************************************
* End reparation-chariot-elevateur-rive-nord.php 
*******************************************************************************/

/*******************************************************************************
* chariot-elevateur-rive-sud.php 
*******************************************************************************/
#toolbar{
    background-color: #052c65;
    color:#FFF;
  }

/*ddls */

.ddls{
  background-color: #052c65;
  padding:10px;
  height:55px;
}

.myselect {
  background-color: #052c65;
  color:#FFF; 
}

select option {
    background-color: $color-1; /*#eecd33*/
    color: $color-2; /*#052c65*/
}

.title{
  color:#052c65;
  text-transform:uppercase;
  font-weight:600;
}

.sku{
  color:#052c65;
}

.plus{
  color:#052c65;
  font-size:40px;
}

/*row trouvez ici...*/
.colorrow{
  margin-top:20px;
  margin-bottom:20px;  
}

.colorrow p{  
  color: $color-2; /*#052c65*/
  font-weight:400;
  text-align:center;  
}

/**  hover images**/

.centrado{
    position: absolute;
    top: 50%;
    left: 50%;   
    color: #FFF;
    transform: translate(-50%, -50%);
}

/* fin hover */

/*end btn search*/
form.inventaire input[type=text] {
    padding: 2px;
    font-size: 14px;
    border: 1px solid white;
    float: left;
    width: 70%;
    background: #fff;
}

form.inventaire button {
    float: left;
    width: 20%;
    padding: 2px;
    background: #FFF;
    color: #8a7e7e;
    font-size: 16px;
    border: 1px solid #fFFF;
    border-left: none;
    cursor: pointer;
}

form.inventaire button:hover {
  background: #eecd33;
}

form.inventaire::after {
  content: "";
  clear: both;
  display: table;
}

/*end btn search*/


/*******************************************************************************
* end chariot-elevateur-rive-sud.php 
*******************************************************************************/


/*******************************************************************************
* chariot-elevateur-montreal-laval.php 
*******************************************************************************/
/*LOCATION HEADER*/

.container-jl{
  position: relative;
  display: inline-block;  
}

.centrage{
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #eecd33; /*yellow*/
    color: #052c65;
    transform: translate(-50%, -50%);
    padding:10px;
    font-size: 1.8rem;
}

/**** section location vente ***/

/*padding-top
padding-right
padding-bottom
padding-left*/

.micajagen{
  /*border:1px solid red;*/
}

.boite-g{
  padding:40px 20px 40px 120px; 
}

 .boite-d{
  padding:40px 120px 40px 20px;
}

.boite-i-g {
 background-color: #eecd33;
 height:300px;
 width:100%;
 position: relative;
}

.boite-c{
  padding:40px 0px 0px 0px;
}

.chariots-col{
 position:absolute;
 z-index:10; 
}

.boite-chariots{
  position: relative;
  top:140px;
}

.chariots{
  position: absolute;
}

.boite-i-d {
 background-color: #052c65;
 height:300px;
 width:100%;
 position: relative;
}

.caption-left {
 position: absolute;
 top: 41%;
 left: 37%;
 background-color: #eecd33;
 font-size: 1.8rem;
 color:#052c65;
}

.caption-right {
 position: absolute;
 top: 41%;
 left: 41%;
 background-color: #052c65 ; /*blue*/
 font-size: 1.8rem;
 color:#eecd33; /*yellow*/
}


/**** section a nous contacter ***/

/* header */

.centrage2{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1rem;
    color: #fff;   
    width: 95%;
    padding: 10px;    
    transform: translate(-50%, -50%);
}

/**row 1**/
.imgcollef {
  background: url('../images/location_section3_bg_left.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  height:100%;
}


.imgcolright{
  background: url('../images/location_section3_bg_right.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  height:100%;
}

.caption{
  margin-top:20px; 
  margin-left:5px; 
  color:#FFF;
}

.periodo {
    position: relative;
    margin-top: 8px;
    left: 120px;
    z-index: 200;
    width: 75%;
}


.text-over-a {
    margin: 10px;
    padding: 5px;
    color: #052c65;
    background-color: #eecd33; /*yellow*/
    width: 100%;
    box-shadow: 5px 4px #afabaa; /*blue*/
    font-size: 1rem;
    float:left;
    text-align:center;
}

.equipement{
  margin-top:2.5%;
  margin-left:25%;   
}

.texttranf ul li{
  text-transform: none;
  font-weight: 100;
  line-height: 6.082ex;
}


/** row 2 **/

.colsm8 {
  padding:0px
 }

.imgcolleft4{
  background: url('../images/location_section4_bg_left.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  height: 100%; 
}

.text-over-bas-credi{  
  margin-top:50px;  
  left: 10px;  
} 

.text-over-bas-credi p{  
  color: #FFF;
  text-align: left;
  width: 100%;
  font-weight: 100;
  font-size: 14px    
}

/** right side AVANTAGES DE CRÉDIT-BAIL**/

.imgcolright4 {
  background: url('../images/location_section4_bg_right.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center; 
  height: 100%; 
}

.text-over-b {
    padding: 5px;
    color: #052c65;
    background-color: #eecd33;
    box-shadow: 5px 5px #afabaa;
    font-size: 1rem;
    z-index: 200;
    text-align: center;
}

.caption2{      
    margin-top:15px;
    margin-left:20px;
    color:#FFF;
}


.periodo-long-t {
    top: 20px;
    left: 120px;
    position: relative;
    z-index: 200;
    width: 75%;
}


.text-over-a {
    margin: 10px;
    padding: 5px;
    color: #052c65;
    background-color: #eecd33; /*yellow*/
    width: 100%;
    box-shadow: 5px 4px #afabaa; /*blue*/
    font-size: 1rem;
    float:left;
    z-index:200;
    text-align:center;
}

.text-over-bas{  
  margin-top:4px;  
  left: 10px;  
}

.text-over-bas p{  
  color: #FFF;
  text-align: left;
  width: 100%;
  font-weight: 100;
  font-size: 14px    
}

/* right side*/
.text-over-conta-credit-bail{
  padding:1px;
  
}

.avantages {
  margin-top:20px;
  margin-left:25%;  
}

.avantages ul li{
  text-transform: none;
  font-weight: 100;
  line-height: 3.4ex;
}

/**** end section a nous contacter ***/

/**Contacter un expert**/


.imgcolright-ult-b {
  background: url('../images/entretien_burst_02.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

.colsm6 {
  padding:0px
 }

 .imgxy {    
    background: url('../images/inventaire_burst_01.jpg');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    height: 100%;
}

.imgxy:hover{
    background: url('../images/inventaire_burst_01_hover.jpg'); 
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    height: 100%;   
}

.text-over-moyen {
  padding:10%;
  color:#fff;
}

.text-over-moyen-right{
  padding:15%;  
}

.enligne{
  font-weight: 600;
  color: #052c65; /*blue*/
}

.enligne-bas{ 
  color: #052c65; /*blue*/
}

/** row b **/
.img-col-left-row-b{
  background: url('../images/location_section7_bg_left.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;  
}

.img-col-right-row-b{
  background: url('../images/location_section7_bg_right.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

.text-over-vente{
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  text-align: left;    
}

/** USAGE **/
.text-over-conta-usage{ 
  position: absolute; 
  top: 40px;
  left:400px;
  z-index:2;  
  color: #052c65;
  background-color: #eecd33; /*yellow*/
  box-shadow: 5px 5px #afabaa;   
}

.text-over-bas-usage-wrap{
  position:relative;
  top: 90px;
  height:auto;
}

.text-over-bas-usage p{  
    color: #FFF;
    text-align: left;
    width: 100%;
    font-weight: 100;
    font-size: 14px;
}

/* NEUF */
.text-over-conta-neuf{ 
  position: absolute; 
  top: 160px;
  left:400px;
  z-index:2;  
  color: #052c65;
  background-color: #eecd33; /*yellow*/
  box-shadow: 5px 5px #afabaa;   
}

.text-over-bas-neuf-wrap{
  position:relative;
  top: 160px;
}

.text-over-bas-neuf p{  
    color: #FFF;
    text-align: left;
    width: 100%;
    font-weight: 100;
    font-size: 14px;
}


/* right side */
.text-over-conta-marques{
  position: absolute;
  top: 20%;
  left: 20%;
  padding: 5px;
  width: 60%; 
  text-align: left;
}

.text-usage ul li {
  text-transform: none;
  font-weight: 100;
  line-height: 3.5ex;
}

/**end seccion contacter un expert**/


/* media for chariot-elevateur-montreal-laval.php */

/* Begin media for location - chariot - vente */

@media (max-width: 576px) { 
   
   .boite-g {
      padding: 5px;
  }

  .boite-d {
    padding: 5px;
  } 

.boite-c {
    padding: 40px;
  }

   .caption {
    margin-top: 0px;
    margin-left: 20px;
    color: #FFF;
    }

  .periodo {
    position: relative;
    left: 47px;
    z-index: 200;
    width: 75%;
    height:200px;
    }

    .equipement {
    margin-left: 15%;
    }

    .caption2 {
    margin-top: 20px;
    margin-left: 30px;
    color: #FFF;
  }

  .periodo-long-t {
    top: 20px;
    left: 55px;
    position: relative;
    z-index: 200;
    width: 75%;
}

.text-over-bas-credi {
    margin-top: 50px;
    /* left: 10px; */
    width: 85%;
}

.avantages {
    margin-top: 1px;
    margin-left: 5%;
}

.text-over-bas-credi p {
    color: #FFF;
    text-align: justify;
    font-weight: 100;
    font-size: 14px;
    padding: 25px;
}

.centrado {
    top: 0%;
    color: #FFF;
    transform: translate(10%, -50%);
    height: 200px;
}

.text-over-conta-marques {
    position: relative;
    top: 5%;
    left: 11%;
    padding: 5px;
    width: 90%;
    text-align: left;
}
  /*end media for chariot-elevateur-montreal-laval.php*/

  /*start media for reparation-chariot-elevateur-rive-nord.php*/
  
  .centrado-rcern {
    color: #FFF;
    transform: translate(10%, -50%);
    height: 200px;
  }
  /*end media for reparation-chariot-elevateur-rive-nord.php*/

  /*start media for chariot-elevateur-rive-sud.php*/
  
  
.bois-search{
    /*margin-top: 30px;*/
    margin: 50px auto;
    border:1px solid red;
}

  .bois-pagination{
    margin-top: 10px;
    margin-bottom: 10px;
  }


  #toolbar {
    padding: 15px;
    height: 50px;
    font-weight: 500;
  }
  
  /*End media for chariot-elevateur-rive-sud.php*/

}/*fin media hasta max-width: 576px*/
/***********************************************/


/*END OF chariot-elevateur-montreal-laval-test.php*/

.pageX {
    section.header {

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
